<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30 mt_sm--5 mt_md--5"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--4 large-size text-center">
       
          <div class="service">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
      
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "cast",
            title: "Products",
            desc: ` Sun Tile is highly experienced in all types of flooring, including Carpet, Laminate, Hardwood, VCT and Vinyl Plank, EVP, as well as, Ceramic, Porcelain and Stone Tile.  .`,
          },
          {
            icon: "layers",
            title: "Installation",
            desc: ` Sun Tile Corporation offers quality installation at affordable prices.  Sun Tile’s flooring crews are among the best in the business.  Our contractors are all seasoned veterans in the industry.  
.`,
          },
          {
            icon: "cast",
            title: "Service",
            desc: ` Sun Tile is staffed with a full-time service department.  Our service crews are all highly trained.  Should the need should ever arise, we will be there to address any issue with your flooring installation.  All installations are warranted for a period of 1 year.`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
